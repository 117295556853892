import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Emitter from "helpers/emitter_helper";
import { customerTypes, dictionary, documentTypes } from "config/customization";

let validationSchemas = {};
validationSchemas[customerTypes.PERSONAL] = Yup.object().shape({
    firstName: Yup.string().required("El nombre es requerido"),
    lastName: Yup.string().required("El apellido es requerido"),
    documentNumber: Yup.string().required(dictionary["DATA.DOCUMENT_NUMBER_REQUIRED"]),
    phone: Yup.string().required("El teléfono es requerido"),
    email: Yup.string()
        .email("El email es inválido")
        .required("El email es requerido"),
    confirmEmail: Yup.string()
        .oneOf([Yup.ref("email"), null], "Los emails no coinciden")
        .required("Confirmar email es requerido"),
});

validationSchemas[customerTypes.BUSINESS] = Yup.object().shape({
    firstName: Yup.string().required("El nombre es requerido"),
    lastName: Yup.string().required("El apellido es requerido"),
    documentNumber: Yup.string().required(dictionary["DATA.DOCUMENT_NUMBER_REQUIRED"]),
    phone: Yup.string().required("El teléfono es requerido"),
    email: Yup.string()
        .email("El email es inválido")
        .required("El email es requerido"),
    confirmEmail: Yup.string()
        .oneOf([Yup.ref("email"), null], "Los emails no coinciden")
        .required("Confirmar email es requerido"),
    businessName: Yup.string().required("Razón social es requerido"),
    cuit: Yup.string().required("CUIT es requerido"),
});

class Datos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            values: {
                firstName: "",
                lastName: "",
                documentNumber: "",
                phone: "",
                email: "",
                confirmEmail: "",
            },
            initialValues: {
                firstName: "",
                lastName: "",
                documentNumber: "",
                phone: "",
                email: "",
                confirmEmail: "",
            },
            currentValidationSchema: validationSchemas[customerTypes.PERSONAL],
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        Emitter.on("GO_TO_BRANCH", () => {
            let customerType = this.props.wizardData["Quien"].customerType;
            let initialValues = this.state.initialValues;
            let customerData = this.props.wizardData["Registrarse"]
                .customerData;
            let documentTypeId = this.props.wizardData["Registrarse"].values
                .idType;
            let documentType = documentTypes.find(
                (dt) => dt.id == documentTypeId
            );

            if (customerType === customerTypes.BUSINESS) {
                initialValues.businessName = customerData.nombre;
                documentType = documentTypes.find((dt) => dt.name == "DNI");
                initialValues.cuit = customerData.cuit;
            } else {
                initialValues.firstName = customerData.nombre
                    .split(" ")
                    .slice(1)
                    .join(" ");
                initialValues.lastName = customerData.nombre
                    .split(" ")[0];
                initialValues.documentNumber =
                    documentType.name === "CUIT"
                        ? customerData.cuit.toString()
                        : customerData.nrodocumento.toString();
            }
            this.setState({
                initialValues,
                customerType,
                documentType,
                currentValidationSchema: customerType
                    ? validationSchemas[customerType]
                    : validationSchemas[customerTypes.PERSONAL],
            });
        });
    }

    isValidated() {
        return this.state.validated;
    }

    handleChange(values) {
        setTimeout(
            function () {
                this.setState({
                    validated: this.formRef.current.isValid,
                    values,
                });
            }.bind(this),
            100
        );
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    validateOnMount={true}
                    innerRef={this.formRef}
                    initialValues={this.state.initialValues}
                    enableReinitialize={true}
                    validationSchema={this.state.currentValidationSchema}>
                    {({ values }) => (
                        <Form onKeyUp={() => this.handleChange(values)}>
                            <Row>
                                <Col>
                                    <div className="alert alert-info">
                                        <strong dangerouslySetInnerHTML={{__html: dictionary["DATA.FILL_REQUIRED_FIELDS"]}}>
                                        </strong>
                                    </div>
                                </Col>
                            </Row>
                            {this.state.customerType ===
                                customerTypes.BUSINESS && (
                                    <>
                                        <Row>
                                            <Col className="h4 font-weight-bold">
                                            {dictionary["DATA.BUSINESS.BUSINESS_FIELDS_TITLE"]}
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col>
                                                <div className="form-group">
                                                    <label>
                                                        {dictionary["DATA.BUSINESS_NAME"]}
                                                        {" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <Field
                                                        className="form-control"
                                                        name="businessName"
                                                        type="text"
                                                    />
                                                    <ErrorMessage
                                                        name="businessName"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label>
                                                        CUIT{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <Field
                                                        className="form-control"
                                                        name="cuit"
                                                        type="text"
                                                    />
                                                    <ErrorMessage
                                                        name="businessName"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                            )}
                            <Row>
                                <Col className="h4 font-weight-bold">
                                {dictionary["DATA.BUSINESS.PERSONAL_FIELDS_TITLE"]}
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            Nombre
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="firstName"
                                            type="text"
                                        />
                                        {/* <small class="form-text text-muted">Persona que asistirá a la sucursal</small> */}
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            Apellido{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="lastName"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            {
                                                dictionary[
                                                    this.state.documentType
                                                        ?.labelKey
                                                ]
                                            }{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="documentNumber"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="documentNumber"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            Teléfono celular{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="phone"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="phone"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            Email{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="email"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>
                                            Confirmar email{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="confirmEmail"
                                            type="text"
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                              }}
                                        />
                                        <ErrorMessage
                                            name="confirmEmail"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {}, null, { forwardRef: true })(Datos);
