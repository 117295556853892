import React, { Component } from "react";
import myImage from "assets/images/enterprisebc-coffeelounge-767x350.avif"

export default class Welcome extends Component {
    // static propTypes = {second: third}

    constructor(props) {
        super(props);
        this.state = { customerType: undefined };
    }

    isValidated = () => {
        return true;
    }
    handleClickBusiness = () => {
        this.setState({ customerType: "BUSINESS" });
        // Emitter.emit('NEXT_BUTTON_CLICK', true);
    }

    handleClickPersonal = () => {
        this.setState({ customerType: "PERSONAL" });
        // Emitter.emit('NEXT_BUTTON_CLICK', true);
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="mb-5 row bg-secondary no-gutters" style={{minHeight: "14rem"}}>

                    <div className="col-12 col-md-4 text-white px-3">
                        <p className=" h3 font-weight-bold">
                            Salas VIPs em Aeroportos
                        </p>
                        <p className="h3">
                            Com American Express, sua melhor experiência começa em terra.
                        </p>
                    </div>
                    <div className="col-12 col-md-8 d-none d-md-block" style={{position: "relative"}}>
                        <img src={myImage} alt="placeholder" style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top left", position: "absolute"}} />
                    </div>
                </div>

   
                <div className="d-flex justify-content-center flex-wrap flex-sm-nowrap text-secondary" style={{ gap: "2rem" }}>
                    <button className={`card-button card-service`}
                    onClick={()=>window.location = "https://citas.debmedia.com/#/company/amexbrasil/getAppointments?schedule=Atencao%20VIP&branch=Aeroporto%20de%20Guarulhos"}>
                        <div> <i className="fa-solid fa-calendar-days"></i></div>
                        <div> Reservar </div>
                    </button>
                    <button className={`card-button card-service`}
                    onClick={()=>window.location = "https://filavirtual.debmedia.com/app/buscar/amexbrasil?queueName=Aten%C3%A7%C3%A3o%20VIP&branchName=Aeroporto%20de%20Guarulhos"}>
                        <div><i class="fa-regular fa-clock"></i></div>
                        <div> Check-In</div>
                    </button>

                </div>
                {/* <pre>{this.state.customerType}</pre> */}
            </div>
        );
    }
}
