import { dictionary } from "config/customization";
import Emitter from "helpers/emitter_helper";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class PersonalBusinessSelection extends Component {
    // static propTypes = {second: third}

    constructor(props) {
      super(props);
      this.state ={customerType: undefined};
    }

    isValidated = () => {
      return !!this.state.customerType;
    }
    handleClickBusiness = () => {
      this.setState({customerType: "BUSINESS"});
      // Emitter.emit('NEXT_BUTTON_CLICK', true);
    }

    handleClickPersonal = () => {
      this.setState({customerType: "PERSONAL"});
      // Emitter.emit('NEXT_BUTTON_CLICK', true);
    }


    render() {
        return (
            <div>
                <div className="mb-5">
                    <h2 style={{textAlign: "center", fontSize: "2rem", fontWeight: "700"}} >
                        {dictionary["PERSONAL_BUSINESS.TITLE"]}
                    </h2>
                </div>
                <div className="d-flex justify-content-center" style={{gap: "2rem"}}>
                    <button className={`card-button ${this.state.customerType === "BUSINESS" && "card-selected"}`}
                    onClick={this.handleClickBusiness}>
                        {dictionary["PERSONAL_BUSINESS.BUTTON_1"]}
                    </button>

                    <button className={`card-button ${this.state.customerType === "PERSONAL" && "card-selected"}`}
                    onClick={this.handleClickPersonal}>
                        {dictionary["PERSONAL_BUSINESS.BUTTON_2"]}
                    </button>
                </div>
                {/* <pre>{this.state.customerType}</pre> */}
            </div>
        );
    }
}
