import Loading from "components/Loading";
import { dictionary, documentTypes } from "config/customization";
import { ErrorMessage, Field, Formik } from "formik";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Col, Form, Row } from "reactstrap";
import { getCustomerData } from "services/bancoSantaCruzCRM";
import * as Yup from "yup";

const fakeLoginValidationSchema = Yup.object().shape({
    idType: Yup.number().required(dictionary["FAKE_LOGIN.DOCUMENT_TYPE_PLACEHOLDER_OPTION"]),
    idNumber: Yup.number().required(dictionary["DATA.DOCUMENT_NUMBER_REQUIRED"]),
});

class FakeLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.formRef = React.createRef();
    }

    isValidated = async () => {

        await this.formRef.current.submitForm();
        if(!this.formRef.current.isValid) return;
        try {
            this.setState({ loading: true });
            let customerData = await getCustomerData(
                this.state.values.idType,
                this.state.values.idNumber
            );
            this.setState({ loading: false });
            this.setState({ customerData });
            return !!this.state.isValid;
        } catch (error) {
            this.setState({ errorFetchingCustomerData: true });
            console.error("Error buscando datos del cliente", error);
            this.setState({ loading: false });
            return false;
        }
    };

    handleChange(values) {
        // este timeout es solo para esperar que react actualice el estado
        // habria que llamar submit desde is validated
        setTimeout(
            function () {
                this.setState({
                    isValid: this.formRef.current.isValid,
                    values: this.formRef.current.values,
                });
            }.bind(this),
            100
        );
    }
    render() {
        return (
            <div>
                <div>
                    <Formik
                        innerRef={this.formRef}
                        initialValues={{
                            idType: "",
                            idNumber: "",
                        }}
                        onSubmit={()=> {console.log("submit")}}
                        validationSchema={fakeLoginValidationSchema}>
                        {({ values, getFieldProps }) => (
                            <Form
                                onChange={() => this.handleChange(values)}
                                style={{ maxWidth: "45ch", margin: "auto" }}>
                                {!this.state.loading && (
                                    <>
                                        <Row>
                                            <Col className="h3">
                                                {dictionary["FAKE_LOGIN.TITLE"]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>
                                                        {
                                                            dictionary[
                                                                "FAKE_LOGIN.DOCUMENT_TYPE"
                                                            ]
                                                        }{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        className="custom-select"
                                                        id="idType"
                                                        {...getFieldProps(
                                                            "idType"
                                                        )}>
                                                        <option value="">
                                                            {
                                                                dictionary[
                                                                    "FAKE_LOGIN.DOCUMENT_TYPE_PLACEHOLDER_OPTION"
                                                                ]
                                                            }
                                                        </option>
                                                        {documentTypes
                                                            .filter(
                                                                (
                                                                    documentType
                                                                ) =>
                                                                    documentType.customerTypes.includes(
                                                                        this
                                                                            .props
                                                                            .wizardData[
                                                                            "Quien"
                                                                        ]
                                                                            ?.customerType
                                                                    )
                                                            )
                                                            .map(
                                                                (
                                                                    documentType
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                documentType.id
                                                                            }
                                                                            value={
                                                                                documentType.id
                                                                            }>
                                                                            {
                                                                                dictionary[
                                                                                    documentType
                                                                                        .labelKey
                                                                                ]
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                    </select>
                                                    <ErrorMessage
                                                        name="idType"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>
                                                        {
                                                            dictionary[
                                                                "FAKE_LOGIN.DOCUMENT_NUMBER"
                                                            ]
                                                        }{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <Field
                                                        className="form-control"
                                                        name="idNumber"
                                                        type="number"
                                                    />
                                                    <ErrorMessage
                                                        name="idNumber"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                {this.state.loading && (
                    <div className="d-flex justify-content-center">
                        <Loading />
                    </div>
                )}
                {this.state.errorFetchingCustomerData && (
                    <SweetAlert
                        title={
                            dictionary[
                                "FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_TITLE"
                            ]
                        }
                        error
                        confirmBtnBsStyle="danger"
                        confirmBtnText={
                            dictionary[
                                "FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_BUTTON_TEXT"
                            ]
                        }
                        onConfirm={() =>
                            this.setState({ errorFetchingCustomerData: false })
                        }>
                        {dictionary["FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_MSG"]}
                    </SweetAlert>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {}, null, { forwardRef: true })(
    FakeLogin
);
