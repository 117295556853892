import { dictionary } from "config/customization";
import Emitter from "helpers/emitter_helper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import lounge1 from "assets/images/American Express Lounge_5.avif"
import { check } from "prettier";
import { set } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { event } from "react-ga";
export default class SelectLounge extends Component {
    // static propTypes = {second: third}

    constructor(props) {
        super(props);
        this.state = {
            customerType: undefined,
            cardNumber: '',
            formattedCardNumber: '',
            loading: false,
            showAlert: false,
            showDemoAlert: true,
        };
    }

    isValidated = async () => {
        if (this.state.cardNumber.length !== 15) return false;
        this.setState({ loading: true });
        let valid = await this.checkCadNumber(this.state.cardNumber);
        this.setState({ loading: false });
        if (valid) {
            return true;
        } else {
            console.log("Card number is invalid");
            this.setState({ showAlert: true });
            return false;
        }
    }
    handleInputChange = (event) => {
        let input = event.target.value.replace(/\D/g, '').substring(0, 15);
        this.setState({ cardNumber: input });
        let formatted = '';
        for (let i = 0; i < input.length; i++) {
            if (i === 4 || i === 10) {
                formatted += ' '; // Insert a space after the 4th and 10th digits
            }
            formatted += input[i];
        }
        this.setState({ formattedCardNumber: formatted });
    };

    handleSubmit(event) {
        event.preventDefault();
        Emitter.emit("NEXT_BUTTON_CLICK", true);
    } 

    checkCadNumber = (cardNumber) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(cardNumber % 2 === 0);
            }, 200);
        });
    }

    render() {
        return (
            <div>
                <div className="mb-4">
                    <h2 style={{ textAlign: "center", fontSize: "2rem", fontWeight: "700" }} >
                        Seja bem-vindo ao The Centurion Lounge
                    </h2>
                </div>
                <div className="" >
                    <div className={`d-block lounge-button mb-4`}
                    >
                        <div className="container-fluid">
                            <div className="row bg-secondary no-gutters" style={{minHeight: "14rem"}}>
                                <div className="col-12 col-md-4 text-white px-3">
                                    <p className="h3 font-weight-bold">
                                        The Centurion Lounge Guarulhos
                                    </p>
                                    <p className="" >
                                        Associados The Centurion® e The Platinum Card® no Brasil têm acesso ao mais novo The Centurion Lounge, localizado no Terminal 3 do Aeroporto Internacional de Guarulhos.
                                    </p>
                                </div>
                                <div className="col-12 col-md-8 d-none d-md-block" style={{position: "relative"}}>
                                    <img src={lounge1} alt="The Centurion Lounge Guarulhos" style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top left", position: "absolute"}} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center" style={{maxWidth: "30rem", margin: "0 auto"}}>
                        <p className="h4">
                            Insira os 15 dígitos de seu cartão American Express®
                            para conferir sua elegibilidade
                        </p>
                        <div className="form-group d-flex justify-content-center">
                            {/* <label htmlFor="creditCardNumber">Credit Card Number</label> */}
                            <form onSubmit={this.handleSubmit}>
                                <input 
                                type="text" pattern="[0-9 ]*" inputmode="numeric"
                                className="form-control text-center"
                                id="creditCardNumber"
                                placeholder="0000 000000 00000"
                                value={this.state.formattedCardNumber}
                                onChange={this.handleInputChange} 
                                style={{width: "22ch"}}
                                required 
                                onEn={() => {console.log("falopa")}}/>

                            </form>
                            {/* <small id="creditCardHelp" className="form-text text-muted">Enter your 16-digit American Express card number.</small> */}
                        </div>
                        <button style={{position: "relative"}} className="btn btn-primary" onClick={() => {Emitter.emit("NEXT_BUTTON_CLICK", true)}} disabled={this.state.loading}>
                            <span className={`${this.state.loading && 'invisible'}`}>SEGUINTE</span>
                            {this.state.loading && <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>}

                        </button>
                    </div>
                </div>
                {/* <pre>{this.state.customerType}</pre> */}
                {this.state.showAlert && 
                <SweetAlert
                title="Limite de Acesso Excedido"
                confirmBtnBsStyle="primary"
                confirmBtnText="ACEITAR"
                onConfirm={() => {
                  this.setState({ showAlert: false });
                }}
              >
                Parece que você atingiu o limite máximo de acessos ao lounge da American Express.
                 Não se preocupe, ainda há muitas outras maneiras de aproveitar seus benefícios! Se precisar
                  de ajuda ou quiser saber mais, estamos aqui para ajudar. Obrigado por fazer parte da família American Express
              </SweetAlert>
                }

        {       this.state.showDemoAlert && 
                <SweetAlert
                title="DEMO PAGE"
                confirmBtnBsStyle="primary"
                confirmBtnText="ACCEPT"
                onConfirm={() => {
                  this.setState({ showDemoAlert: false });
                }}
              >
                This is a demo page. Even though data is not sent nor stored, please do not enter any sensitive information.
              </SweetAlert>
                }
            </div>
        );
    }
}
