import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import Loading from "components/Loading";
import { customerTypes, dictionary, documentTypes } from "config/customization";

class Confirmacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    // todo mover a componentDidUpdate
    let documentType = this.props.wizardData?.["Datos"]?.documentType;
    let prettyDate;
    let prettyTime;
    let branch
    if (this.props.wizardData?.['Sedes']?.branches) {
      branch = this.props.wizardData['Sedes']?.branches.find((b) => b.id === this.props.wizardData['Sedes'].branch);
    }
    if (this.props.wizardData?.['Fecha y hora']?.startAt) {
      try{
        let dateFormater = new Intl.DateTimeFormat(['es-AR', 'es-ES', 'es']);
        let timeFormater = new Intl.DateTimeFormat(['es-AR', 'es-ES', 'es'], {timeStyle: "short"});
        let date = new Date(this.props.wizardData['Fecha y hora']?.startAt);
        prettyDate = dateFormater.format(date);
        prettyTime = timeFormater.format(date);
      } catch (e) {
        console.error("Error parsing date", e);
        prettyDate = this.props.wizardData['Fecha y hora']?.startAt.split("T")[0]
        prettyTime = this.props.wizardData['Fecha y hora']?.startAt.split("T")[0].split(":00-")[0];
      }
    }
    return (
      <React.Fragment>
        {this.props.loading ?
          <Row>
            <Col className="d-flex justify-content-center">
              <Loading />
            </Col>
          </Row>
          : <>
          <div className="h2 text-center font-weight-bold mb-5">{dictionary["CONFIRMATION.HEADING"]}</div>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Nombre</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.firstName : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Apellido</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.lastName : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>{dictionary[documentType?.labelKey]}</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.documentNumber : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Teléfono celular</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.phone : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Email</label><br />
                <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.email : '-'}</span>
              </div>
            </Col>
            {this.props.wizardData["Quien"]?.customerType === customerTypes.BUSINESS &&
            <Col>
            <div className="form-group">
              <label>Razón Social</label><br />
              <span>{this.props.wizardData['Datos'] ? this.props.wizardData['Datos'].values.businessName : '-'}</span>
            </div>
          </Col>
            }
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Fecha</label><br />
                <span>{prettyDate}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Hora</label><br />
                <span>{prettyTime}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Sucursal</label><br />
                <span>{branch?.name}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Dirección</label><br />
                <span>{branch?.direccion}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>Provincia</label><br />
                <span>{branch?.estado}</span>
              </div>
            </Col>
          </Row>
        </>
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading } = state.Home;
  return {
    loading
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Confirmacion);

