import React, { Component } from "react";
import { dictionary, secondStepServices } from "config/customization";
import Emitter from "helpers/emitter_helper";

let containerStyle = {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "center",
};

export default class GoToBranch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedGoToBranch: false,
        };
    }

    handleRedirect = (url) => {
        top.location = url;
    }

    isValidated = () => {

        return this.state.selectedGoToBranch;
    };

    render() {
        
        return (
            <div>
                <div className="text-center mb-5 service-help-section" dangerouslySetInnerHTML={{__html: this.props.wizardData["PreService"]?.selectedService?.helperText}}></div>
                <div style={containerStyle} className="mb-4">
                        {this.props.wizardData["PreService"]?.selectedService?.helperButtons?.map((button)=> 
                        <button
                            key={button.label}
                            className="card-button card-primary"
                            onClick={()=> this.handleRedirect(button.redirectURL)}
                            >
                            {button.label}
                        </button>
                        )}
                         <button
                            onClick={()=> this.setState({selectedGoToBranch: true})}
                            className={`card-button ${this.state.selectedGoToBranch && "card-selected"}`}
                            >
                            {dictionary["GO_TO_BRANCH.BUTTON"]}
                        </button>
                </div>
            </div>
        );
    }
}
