import React, { Component } from "react";
import { dictionary, secondStepServices } from "config/customization";
import Emitter from "helpers/emitter_helper";

let containerStyle = {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "center",
};

export default class ServiceSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedService: undefined,
            filteredServices: [],
        };
    }

    componentDidMount() {
        Emitter.on("PRE_SERVICE_SELECTED", (selectedPreService) => {
            let filteredServices = [];
            let customerData = this.props.wizardData?.["Registrarse"]?.customerData;
            if (selectedPreService && customerData) {
                filteredServices = this.filterSecondStepServices(
                    selectedPreService,
                    customerData,
                    secondStepServices
                );
            }
            this.setState({ filteredServices });
        });
    }

    componentWillUnmount() {
        Emitter.off("PRE_SERVICE_SELECTED");
    }

    isValidated = () => {
        if (this.state.selectedService) {
            // TODO Cambiar el nombre de GO_TO_BRANCH
            Emitter.emit("GO_TO_BRANCH");
            Emitter.emit("SET_SERVICE", this.state.selectedService);
            return !!this.state.selectedService;
        }
    };

    handleClick = (service) => {
        this.setState({ selectedService: service });
        // Emitter.emit('SET_SERVICE', service);
    };

    filterSecondStepServices = (selectedPreService, customerData, services) => {
        return services.filter((service) => {
            return (
                service.availableIn.firstStepServicesIds.includes(
                    selectedPreService.id
                ) &&
                service.availableIn.attentionModelsIds.includes(
                    customerData.modelo
                )
            );
        });
    };

    render() {
        return (
            <div>
                <div className="mb-4">
                    <p className="text-center h3">
                        {
                            this.props.wizardData?.["PreService"]
                                ?.selectedService?.label
                        }
                    </p>
                    <p className="text-center h5">{dictionary["SERVICE_SELECTION.TITLE"]}</p>
                </div>
                <div style={containerStyle}>
                    {this.state.filteredServices.map((service, index) => (
                        <button
                            className={`card-button card-service ${
                                this.state.selectedService?.id ===
                                    service?.id && "card-selected"
                            }`}
                            key={service.id}
                            onClick={() => this.handleClick(service)}>
                            <div>
                                {index < 9 ? "0" + (index + 1) : index + 1}
                            </div>
                            <div>{service.label}</div>
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}
