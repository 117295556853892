import { attentionModels } from "config/customization";

const BASE_URL = process.env.REACT_APP_BANCO_SANTA_CURZ_CRM_BASE_SERVER_URL || process.env.REACT_APP_PUBLIC_URL || "";
export async function getCustomerData(documentTypeId, documentNumber) {
    let requestOptions = {
        method: "GET",
        redirect: "follow",
    };

    return fetch(
        `${BASE_URL}/api/Cliente?TipoDocumento=${documentTypeId}&NumeroDocumento=${documentNumber}`,
        requestOptions
    )
        .then((response) => response.json())
        .then((res) => {
            if (res.status === 400) {
                // manejar usuario no reconocido
                // Creamos un usuario falso
                return {
                    nrodocumento: "",
                    nombre: "",
                    cuit: "",
                    modelo: "-1",
                };
            }
            // si el modelo no es ninguno de los configurados le mandamos el de no cliente
            if (!res.modelo || !attentionModels.map(am => am.code).includes(res.modelo)){
                res.modelo = "-1";
            }
            return res;
        });
}
