import React, { Component } from "react";
import { dictionary, firstStepServices } from "config/customization";
import Emitter from "helpers/emitter_helper";

let containerStyle = {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "center",
};

export default class PreServiceSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedService: undefined,
        };
    }

    isValidated = () => {
        return !!this.state.selectedService;
    };

    handleClick = (service) => {
        this.setState({ selectedService: service });
        Emitter.emit("PRE_SERVICE_SELECTED", service);
    };
    render() {
      let customerName = this.props.wizardData?.["Registrarse"]?.customerData?.nombre;
      if(customerName){
        customerName = customerName.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      }
        return (
            <div>
              <div className="mb-4">
                <p className="text-center h3">
                {dictionary["PRE_SERVICE_SELECTION.TITLE"].replace("{{name}}", customerName ? " " + customerName : "").replace(" !", "!")}
                </p>
                <p className="text-center h5">
                  {dictionary["PRE_SERVICE_SELECTION.SUB_TITTLE"]}
                </p>
              </div>
                <div style={containerStyle}>
                    {firstStepServices.map((service, index) => (
                        <button
                            className={`card-button card-service ${this.state.selectedService?.id === service?.id && "card-selected"}`}
                            key={service.id}
                            onClick={() => this.handleClick(service)}>
                              <div>
                              {index < 9 ? "0"+(index + 1): index + 1}
                              </div>
                              <div>
                            {service.label}

                              </div>
                        </button>
                    ))}
                </div>
                <div>
                  <p className="text-center mt-4">
                   {dictionary["PRE_SERVICE_SELECTION.FOOTER"]}
                  </p>
                </div>
                {/* <pre>
                {JSON.stringify(this.props, null, 2)}
                </pre> */}
            </div>
        );
    }
}
