/**
 * Aca estan las customizaciones y textos para Banco Santa Cruz
 * Usamos un js para que nos permita tener comentarios
 * pero no debe haber ninguna lógica en este archivo
 */


// Textos fijos que se usan en la app
export const dictionary = {
    "PERSONAL_BUSINESS.TITLE": "¿Para quién querés sacar el turno?",
    "PERSONAL_BUSINESS.BUTTON_1": "Para una empresa",
    "PERSONAL_BUSINESS.BUTTON_2": "Personal",
    "DOCUMENT_TYPES.DNI": "Documento Nacional de Identidad",
    "DOCUMENT_TYPES.LC": "Libreta Cívica",
    "DOCUMENT_TYPES.LE": "Libreta Enrolamiento",
    "DOCUMENT_TYPES.DNE": "Documento Nacional para Extranjero",
    "DOCUMENT_TYPES.CUIT": "CUIT",
    "FAKE_LOGIN.DOCUMENT_TYPE": "Tipo de documento",
    "FAKE_LOGIN.DOCUMENT_NUMBER": "Numero de documento",
    "FAKE_LOGIN.DOCUMENT_TYPE_PLACEHOLDER_OPTION":
        "Por favor seleccione un tipo de documento",
    "DATA.NAME": "Nombre",
    "DATA.SURNAME": "Apellido",
    "DATA.EMAIL": "Email",
    "DATA.REQUIRED_FIELDS_MSG":
        'Por favor, complete los campos marcados con <span class="text-danger">*</span>',
    "DATA.CELLPHONE": "Teléfono celular",
    "DATA.CONFIRM_EMAIL": "Confirmar email",
    "DATA.EMAIL_IS_REQUIRED": "El email es requerido",
    "DATA.EMAIL_IS_INVALID": "El email es inválido",
    "DATA.PHONE_IS_REQUIRED": "El teléfono es requerido",
    "DATA.DOCUMENT_NUMBER_REQUIRED": "El numero de documento es requerido",
    "DATA.SURNAME_IS_REQUIRED": "El apellido es requerido",
    "DATA.NAME_IS_REQUIRED": "El nombre es requerido",
    "CONFIRMATION.HEADING" : "Confirme los datos de su cita",
    "DATA.BUSINESS.BUSINESS_FIELDS_TITLE": "Datos de la empresa:",
    "DATA.BUSINESS.PERSONAL_FIELDS_TITLE": "Datos de la Persona que asistirá a la cita:",
    "FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_TITLE": "Error",
    "FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_MSG": "Ha ocurrido un error intentando obtener los datos del cliente",
    "FAKE_LOGIN.GET_CUSTOMER_DATA_ERROR_BUTTON_TEXT": "Aceptar",
    "GO_TO_BRANCH.BUTTON": "Necesito ir a la Sucursal",
    "FAKE_LOGIN.TITLE": "Identifícate con tus datos",
    "PRE_SERVICE_SELECTION.TITLE": "¡Bienvenido {{name}}!",
    "PRE_SERVICE_SELECTION.SUB_TITTLE": "Seleccione una opción",
    "PRE_SERVICE_SELECTION.FOOTER": " Recordá que tenemos Atención prioritaria para clientes con movilidad reducida, capacidades especiales o embarazadas. Acercate a nuestras sucursales sin necesidad de sacar turno.",
    "SERVICE_SELECTION.TITLE": "Seleccione una opción",
    "SUCURSAL.NO_BRANCH_MSG" : "Lo sentimos, no hay sucursales disponibles para esta atención",
    "FECHA.NO_DATES_AVAILABLE_IN_CURRENT_MONTH": "Para este mes no hay horarios disponibles.",
    "DATA.FILL_REQUIRED_FIELDS" : "Por favor, complete los campos marcados con <span class=\"text-danger\"> * </span>",
    "DATA.BUSINESS_NAME": "Razón Social",
};


/** Grupos de tramites
 * Son los botones que se muestran al principio.
 * label: es el texto que se muestra en el botón
 * id: es un numero único que hay que darle para después referenciar en el segundo grupo de botones
 * helperText: es el texto que se muestra para la derivación
 * helperButtons: son los botones para derivación donde label es el texto del boton y redirectURL es la url a redirigir
 */
export const firstStepServices = [
    {
        label: "Solicitar productos y servicios",
        id: 1,
        helperText: `<p class="h4">A través de Home Banking y en pocos pasos podes:<br/>
             Solicitar tu préstamo de hasta $ 3.000.000.<br/>
             Pedir tu Adelanto de Sueldo o Adelanto de Jubilación.<br/>
             Operar con Plazo Fijo y Fondos Común de Inversión.</p>`,
        helperButtons: [
            {
                redirectURL: "https://www.bancosantacruz.com/personas",
                label: "Resolver ahora",
            },
        ],
    },
    {
        label: "Retirar un producto",
        id: 2,
        helperText: `<p class="h4"><b>¿Dónde está mi tarjeta?</b><br/><br/>
            Si tu Tarjeta es Mastercard comunicate con Andreani 0810-122-1111 / 0800-122-1112
            <a href="https://bancosantacruz.andreani.com/">https://bancosantacruz.andreani.com/</a> <br/><br/>
            Si tu Tarjeta es Visa comunicate con OCA 0800-999-7700 de Lun a Vier de 9 a 18 hs
            o ingresá en: <a href="https://www.oca.com.ar/">https://www.oca.com.ar/</a><br/>
            En Buscá tu envío elegí Tarjetas OCA Geo e ingresá el número que recibiste por mail o sms (mensaje de texto)</p>`,
        helperButtons: [
            {
                redirectURL: "https://www.bancosantacruz.com/personas/ayuda/tus-gestiones/tarjeta-de-credito",
                label: "Resolver ahora",
            },
        ],
    },
    {
        label: "Realizar gestiones sobre mis productos",
        id: 3,
        helperText: `<p class="h4"><b>Descubrí las opciones que tenés para operar más rápido</b></p>
            <p>Chatea con <b>La Mano</b> y consultá todas tus dudas
            Agendalo y empezá a chatear <b>+54 9 2966 75-6639</b></p>
            <p>Para <b>hacer</b> un reclamo, podés comunicarte con nuestro <b>Contact Center</b>
             al <b>0800-555-7278</b> , desde el exterior: <b>+54-341-4294970</b>, de <b>lunes a viernes</b> de <b>7 a 19 hs.-</b></p>
            <p>Para <b>desconocer consumos</b> de tu <b>Tarjeta de Crédito</b> Ingresá a la web de tu marca.</p>`,
        helperButtons: [
            {
                redirectURL: "https://www.bancosantacruz.com/personas/ayuda/tus-gestiones",
                label: "Resolver ahora",
            },
        ],
    },
    {
        label: "Operaciones de caja",
        id: 4,
        helperText: `<p class="h4"><b>Extracciones de efectivo</b></p>
            <p>Retirá dinero por <b>Cajeros Automáticos</b>, locales de <b>Santa Cruz Servicios</b> y comercios adheridos a <b>Extra Cash</b>.</p>
            <p class="h4"><b>Depósitos</b></p>
            <p><b>Depositá</b> efectivo en nuestra red de <b>Cajeros Automáticos</b> y <b>Terminales de Autoservicios</b></p>
            <p class="h4"><b>Pagos</b></p>
            <p>Pagá impuestos por <b>Home Banking</b>, <b>Mobile Banking</b>, <b>cajeros</b>, locales <b>Santa Cruz Servicios</b> o en <b>pluspagos.com</b>.</p>
            `,
        helperButtons: [
            {
                redirectURL: "https://www.bancosantacruz.com/personas/ayuda/tus-gestiones/cuentas",
                label: "Resolver ahora",
            },
        ],
    },
    {
        label: "Acceder a mi Caja de Seguridad",
        id: 5,
        helperText: "",
        helperButtons: [],
    },
];

/** Botones de tramites 
 * Label: Texto del botón
 * availableIn: Configuración de donde mostrar
 * availableIn.firstStepServicesIds: Los id del los botones del primer paso donde se tiene que mostrar esta opción
 * availableIn.attentionModelsIds: Los ids de los modelos de atención para el cual se tiene mostrar el botón
 * Ejemplo:
 * si `firstStepServicesIds: [1,3]` y `attentionModelsIds: ["11", "21", "23", "25"]`
 * este botón se mostraria si en el primer paso elegi el botón con id "1" O "3" Y para los modelos de atención "11", "21", "23" O "25"
 * 
 * id: id único para cada botón para trackearlos
 * scheduleName: tiene que ser el mismo nombre de la Agenda, porque la selección de la agenda se hace con el nombre y la sucursal elegida,
 * para cada sucursal tiene que tener una agenda con este mismo nombre
*/
export const secondStepServices = [
    {
        label: "Solicitud de productos",
        availableIn: {
            firstStepServicesIds: [1],
            attentionModelsIds: ["11", "21", "23", "25"],
        },
        id: 3,
        scheduleName: "Solicitud de productos",
    },
    {
        label: "Solicitud de préstamos",
        availableIn: {
            firstStepServicesIds: [1],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 2,
        scheduleName: "Solicitud de préstamos",
    },
    {
        label: "Apertura de cuenta",
        availableIn: {
            firstStepServicesIds: [1],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 1,
        scheduleName: "Apertura de cuenta",
    },
    {
        label: "Solicitud de tarjetas, seguros o packs",
        availableIn: {
            firstStepServicesIds: [1],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 4,
        scheduleName: "Solicitud de tarjetas seguros o packs",
    },
    {
        label: "Inversiones / Depósitos a Plazo Fijo",
        availableIn: {
            firstStepServicesIds: [1],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 18,
        scheduleName: "Inversiones Depósitos a Plazo Fijo",
    },
    {
        label: "Retiro de productos",
        availableIn: {
            firstStepServicesIds: [2],
            attentionModelsIds: ["11", "21", "23", "25"],
        },
        id: 5,
        scheduleName: "Retiro de productos",
    },
    {
        label: "Tarjetas",
        availableIn: {
            firstStepServicesIds: [2],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 6,
        scheduleName: "Tarjetas",
    },
    {
        label: "Chequeras",
        availableIn: {
            firstStepServicesIds: [2],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 7,
        scheduleName: "Chequeras",
    },
    {
        label: "Cheques devueltos",
        availableIn: {
            firstStepServicesIds: [2],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 8,
        scheduleName: "Cheques devueltos",
    },
    {
        label: "Gestiones y Consultas",
        availableIn: {
            firstStepServicesIds: [3],
            attentionModelsIds: ["11", "21", "23", "25"],
        },
        id: 9,
        scheduleName: "Gestiones y Consultas",
    },
    {
        label: "Refinanciación de deudas",
        availableIn: {
            firstStepServicesIds: [3],
            attentionModelsIds: [12, 13, 24],
        },
        id: 10,
        scheduleName: "Refinanciación de deudas",
    },
    {
        label: "Certificación de firmas",
        availableIn: {
            firstStepServicesIds: [3],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 11,
        scheduleName: "Certificación de firmas",
    },
    // {
    //     label: "Otras Gestiones y consultas",
    //     availableIn: {
    //         firstStepServicesIds: [3],
    //         attentionModelsIds: [12, 13, 24],
    //     },
    //     id: 12,
    //     scheduleName: "Otras Gestiones y consultas",
    // },
    {
        label: "Operaciones de Caja",
        availableIn: {
            firstStepServicesIds: [4],
            attentionModelsIds: ["11", "21", "23", "25"],
        },
        id: 13,
        scheduleName: "Operaciones en Caja",
    },
    {
        label: "Extracción de efectivo mayor a $100.000",
        availableIn: {
            firstStepServicesIds: [4],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 14,
        scheduleName: "Extracción de efectivo mayor a 100000",
    },
    {
        label: "Depósitos de efectivo mayor a $100.000",
        availableIn: {
            firstStepServicesIds: [4],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 15,
        scheduleName: "Depósitos de efectivo mayor a 100000",
    },
    {
        label: "Extracción, depósito de moneda extranjera",
        availableIn: {
            firstStepServicesIds: [4],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 16,
        scheduleName: "Extracción depósito de moneda extranjera",
    },
    {
        label: "Cobro de cheques",
        availableIn: {
            firstStepServicesIds: [4],
            attentionModelsIds: ["12", "13", "24", "-1"],
        },
        id: 17,
        scheduleName: "Cobro de cheques",
    },
    {
        label: "Acceder a mi Caja de Seguridad",
        availableIn: {
            firstStepServicesIds: [5],
            attentionModelsIds: ["11", "21", "23", "25", "12", "13", "24", "-1"],
        },
        id: 18,
        scheduleName: "Acceso a Caja de Seguridad",
    },
];


/**
 * Personalizado es el código "11", "21", "23", "25"
 * 
 * Nota: el webserice no tiene un modelo de atención para no clientes 
 * y devuelve un error 400 si el usuario no esta en la base de clientes
 * En ese caso le asignamos un código -1 para poder hacer la lógica con los no clientes también
 * 
 * No debería camiar nada de esto al menos que cambie algo en el webservice de BSC
 */
export const attentionModels = [
    {
        code: "11",
        description: "PERSONALIZADO",
    },
    {
        code: "12",
        description: "PRIORIZADO",
    },
    {
        code: "13",
        description: "MASIVO",
    },
    {
        code: "21",
        description: "AGRO",
    },
    {
        code: "23",
        description: "PyMES",
    },
    {
        code: "24",
        description: "MyP",
    },
    {
        code: "25",
        description: "INSTITUCIONES",
    },
    {
        code: "-1", // id fake que le damos a los no clientes
        description: "NO_CLIENTES",
    },
];

// No cambiar
export const documentTypes = [
    {
        name: "DNI",
        labelKey: "DOCUMENT_TYPES.DNI",
        customerTypes: ["PERSONAL"],
        id: 31,
        debQDocumentType: "dni",
    },
    {
        name: "LC",
        labelKey: "DOCUMENT_TYPES.LC",
        customerTypes: ["PERSONAL"],
        id: 33,
        debQDocumentType: "libretaCivica",
    },
    {
        name: "LE",
        labelKey: "DOCUMENT_TYPES.LE",
        customerTypes: ["PERSONAL"],
        id: 32,
        debQDocumentType: "libretaEnrolamiento",
    },
    {
        name: "DNE",
        labelKey: "DOCUMENT_TYPES.DNE",
        customerTypes: ["PERSONAL"],
        id: 46,
        debQDocumentType: "passport",
    },
    {
        name: "CUIT",
        labelKey: "DOCUMENT_TYPES.CUIT",
        customerTypes: ["BUSINESS"],
        id: 35,
        debQDocumentType: "cuit",
    },
];

// No cambiar
export const customerTypes = {
    BUSINESS: "BUSINESS",
    PERSONAL: "PERSONAL",
};
